import Link from "next/link";
import { useState, type Dispatch, type SetStateAction } from "react";
import EvalSummaryModal from "~/components/EvalSummaryModal";
import KPIs from "~/components/lists/MainPage/Dashboard/DashboardInsights/KPInsights";
import PrimaryInsights from "~/components/lists/MainPage/Dashboard/DashboardInsights/PrimaryInsights";
import RankInsights from "~/components/lists/MainPage/Dashboard/DashboardInsights/RankInsights";
import { useList } from "~/providers/ListProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { TrackerProvider } from "~/providers/TrackerProvider";
import { api } from "~/utils/api";
import SecondaryInsights from "./DashboardInsights/SecondaryInsights";
import SelectDependency from "./DashboardInsights/SelectDependency";
import LightDashboard from "../Dashboard/DashboardInsights/LightDashboard";
import { InsightsPerTracker } from "@openqlabs/drm-db";
import ListEvalProgress from "../../Utils/ListEvalProgress";
import { Enums } from "@openqlabs/utils";
export default function Dashboard({
  setSubMenu
}: Readonly<{
  setSubMenu: Dispatch<SetStateAction<string>>;
}>) {
  const list = useList();
  const [showModal, setShowModal] = useState(false);
  const [teamAccountUser] = useTeamAccountUser();
  const userId = teamAccountUser?.id;
  const {
    activeTeamAccount
  } = useTeamAccount();
  const activeTeamAccountId = activeTeamAccount?.id;

  // to change if we allow mutliple trackers again:
  //const selectedDependency = useDependencyName() as string;
  const trackerId = list.trackers?.[0].id;
  const dependency = list.trackers?.[0].dependencies?.[0];
  const dashboardStats = (api.listEvaluation.getDashboardStats.useQuery({
    listId: list.id,
    teamAccountId: activeTeamAccountId
  }).data as {
    listId: string;
    insightsPerTracker: InsightsPerTracker;
    waitingEvalsCount: number;
    doneEvalsCount: number;
    repoCount: number;
    userCount: number;
    status: string;
    updatedAt: Date;
    error: string | null | undefined;
  });
  const {
    insightsPerTracker,
    waitingEvalsCount,
    doneEvalsCount,
    repoCount,
    userCount,
    status,
    updatedAt,
    error
  } = dashboardStats ?? {};
  const someWaiting = status === "waiting";
  const listEvalError = error;
  const dependencyInsights = insightsPerTracker?.[trackerId]?.[dependency] ?? {};
  const noResult = Object.keys(dependencyInsights).length === 0;
  const noContacts = !repoCount && !userCount;
  const {
    primaryLeads,
    primaryNewLeads,
    primaryCustomers,
    leads,
    newLeads,
    customers,
    oneTimeDevs,
    partTimeDevs,
    fullTimeDevs,
    activeTeams
  } = dependencyInsights;
  return <div data-sentry-component="Dashboard" data-sentry-source-file="index.tsx">
      {(noContacts || noResult) && <div className="flex w-full gap-20 leading-none">
          <div className="w-full leading-6">
            Please make sure you have
            <Link className="text-accent" href={`/lists/${list.id}/dependency-tracking`}>
              {" "}
              added a dependency tracker
            </Link>
            ,{" "}
            <Link className="text-accent" href={`/lists/${list.id}/add-contact`}>
              a user contact
            </Link>{" "}
            and{" "}
            <button className="text-accent" onClick={() => setShowModal(true)}>
              {" "}
              run evaluations{" "}
            </button>{" "}
            with said tracker to see relevant statistics.
          </div>
          <div>
            <SelectDependency onDependencyChange={() => void 0} defaultDependencyName={dependency} />
          </div>
        </div>}
      {(someWaiting || listEvalError) && <ListEvalProgress waitingEvalsCount={waitingEvalsCount} doneEvalsCount={doneEvalsCount} />}

      {showModal && <TrackerProvider initialTracker={list.trackers[0]}>
          <EvalSummaryModal setShowModal={setShowModal} showModal={showModal} list={list} userId={userId} teamAccountId={activeTeamAccountId} />
        </TrackerProvider>}
      {dependency && !noContacts && !noResult && <div className="w-full gap-20 leading-none">
          <div className="">
            {list.mode == Enums.ListMode.LIGHT ? <LightDashboard leads={leads} customers={customers} repoCount={repoCount} userCount={userCount} dependency={dependency} /> : <>
                <PrimaryInsights leads={primaryLeads} newLeads={primaryNewLeads} customers={primaryCustomers} repoCount={repoCount} userCount={userCount} activeTeams={activeTeams} dependency={dependency} />

                <SecondaryInsights userCount={userCount} leads={leads} newLeads={newLeads} customers={customers} />
                <KPIs setSubMenu={setSubMenu} leads={leads} newLeads={newLeads} customers={customers} />
              </>}

            <RankInsights oneTimeDevs={oneTimeDevs} partTimeDevs={partTimeDevs} fullTimeDevs={fullTimeDevs} />
            <div className="my-4">
              Insights were last updated on {updatedAt.toLocaleDateString()}.
            </div>
          </div>
          {/*  <UsageGraph selectedDependency={dependency} /> */}
        </div>}
    </div>;
}