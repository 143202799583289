import Head from "next/head";
import Overview from "~/components/Overview";
import New from "~/components/lists/New";
import { useLists } from "~/providers/ListsProvider";
import Layout, { LayoutContent, LayoutMain } from "~/components/layout/Layout";
import NavBar from "~/components/layout/NavBar";
import Sidebar from "~/components/layout/Sidebar";
export default function HomePage() {
  const {
    lists
  } = useLists();
  return <Layout data-sentry-element="Layout" data-sentry-component="HomePage" data-sentry-source-file="index.tsx">
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <title>OpenQ DRM</title>
      </Head>
      <Sidebar data-sentry-element="Sidebar" data-sentry-source-file="index.tsx" />
      <LayoutMain data-sentry-element="LayoutMain" data-sentry-source-file="index.tsx">
        <NavBar data-sentry-element="NavBar" data-sentry-source-file="index.tsx" />
        {lists.length > 0 ? <div className="flex h-[calc(100vh_-_36px)] overflow-y-hidden w-full justify-center z-0 px-8">
            <div className="flex max-w-full grow flex-col justify-between">
              <Overview />
            </div>
          </div> : <LayoutContent>
            <New />
          </LayoutContent>}
      </LayoutMain>
    </Layout>;
}