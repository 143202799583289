import { type Table, flexRender } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import SkeletonRow from "~/components/lists/MainPage/UsersTable/SkeletonRow";
import useVirtualPadding from "~/hooks/useVirtualPadding";
import { getScrollElement, stickToRight } from "~/components/lists/MainPage/SharedTable/utils";
import { ListsRowData } from "./index";
const Body = ({
  table
}: {
  table: Table<ListsRowData>;
}) => {
  const {
    rows
  } = table.getRowModel();
  const rowCount = rows.length;
  //Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtualizer({
    getScrollElement,
    count: rowCount,
    estimateSize: () => 43,
    overscan: 20
  });
  const [paddingTop, paddingBottom] = useVirtualPadding(rowVirtualizer);
  return <tbody data-sentry-component="Body" data-sentry-source-file="Body.tsx">
      {paddingTop > 0 && <tr>
          <td style={{
        height: `${paddingTop}px`
      }} />
        </tr>}

      {rowVirtualizer.getVirtualItems().map(virtualRow => {
      const row = rows[virtualRow.index];
      const isLoaderRow = virtualRow.index > rows.length - 1;
      const isLoading = isLoaderRow;
      const isLastRow = isLoaderRow;
      if (isLoading) return <SkeletonRow numbering={virtualRow.index + 1} />;
      if (isLastRow) {
        return null;
      }
      return <tr className="z-30 border-y" key={row.id}>
            {row.getVisibleCells().map((cell, index) => {
          return <td className={`border-r ${index > 0 ? "z-10 border-gray-200" : stickToRight}`} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>;
        })}
            <td className="z-10 border-gray-200"></td>
          </tr>;
    })}

      {paddingBottom > 0 && <tr>
          <td style={{
        height: `${paddingBottom}px`
      }} />
        </tr>}
    </tbody>;
};
export default Body;