import { PresentationChartLineIcon, BoltIcon, ClockIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import { useState } from "react";
import { getLocalDateString, validateDateFormat } from "~/lib/date";
import { useLists } from "~/providers/ListsProvider";
import { useLogger } from "~/providers/LoggerProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/utils/api";
import Button from "../base/Button";
import Input from "../base/Input";
import Label from "../base/Label";
import { type ListMode } from "@openqlabs/drm-db";
import { Enums } from "@openqlabs/utils";
export default function New() {
  const router = useRouter();
  const {
    activeTeamAccount
  } = useTeamAccount();
  const [teamAccountUser] = useTeamAccountUser();
  const {
    handleRefetch
  } = useLists();
  const logger = useLogger();
  const sixWeeksAgo = getLocalDateString(new Date(new Date().setDate(new Date().getDate() - 42)));
  const [listName, setListName] = useState("");
  const [startDate, setStartDate] = useState(sixWeeksAgo);
  const [endDate, setEndDate] = useState("");
  const [startDateValid, setStartDateValid] = useState(true);
  const [endDateValid, setEndDateValid] = useState(true);
  const [listMode, setListMode] = useState<ListMode>(Enums.ListMode.LIGHT);
  const createList = api.list.listCreate.useMutation({
    onSuccess: async data => {
      await router.push(`/lists/${data.id}/dependency-tracking`).catch(err => logger.error(err, "New.tsx")).then(() => {
        handleRefetch();
      });
    }
  });
  function handleAddList() {
    createList.mutate({
      name: listName,
      contactIds: [],
      teamAccountId: activeTeamAccount.id,
      userId: (teamAccountUser?.id as string),
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      mode: listMode
    });
  }
  const handleDashes = (value: string, dateString: string) => {
    let workingValue = value;
    if (value.length === 4 || value.length === 7) {
      if (dateString.length < value.length) {
        workingValue = value + "-";
      } else {
        workingValue = value.slice(0, -1);
      }
    }
    return workingValue;
  };
  const handleStartDateChange = (value: string) => {
    const workingValue = handleDashes(value, startDate);
    const isValid = validateDateFormat(workingValue);
    if (workingValue) setStartDateValid(isValid);else setStartDateValid(false);
    setStartDate(workingValue);
  };
  const handleEndDateChange = (value: string) => {
    const workingValue = handleDashes(value, endDate);
    const isValid = validateDateFormat(workingValue);
    setEndDateValid(isValid);
    setEndDate(workingValue);
  };
  const switchListMode = (mode: ListMode) => {
    if (mode !== Enums.ListMode.DEEP) {
      setStartDate(sixWeeksAgo);
      setEndDate("");
    }
    setListMode(mode);
  };
  return <div className="mx-auto w-full max-w-[1200px]" data-sentry-component="New" data-sentry-source-file="New.tsx">
      <div className="mt-6 flex content-center items-center pb-4">
        <PresentationChartLineIcon className="sm-icon mr-2" data-sentry-element="PresentationChartLineIcon" data-sentry-source-file="New.tsx" />
        <span className="flex-1 text-2xl font-semibold">Create a new list</span>
      </div>
      <p className="mt-6 text-lg">
        Start a new list to track Github contacts, organize your interactions,
        and monitor dependencies.
      </p>
      <div className="flex flex-col gap-6 border-b border-divider py-6 pb-10">
        <div>
          <Input value={listName} setValue={setListName} placeholder="My list name" data-sentry-element="Input" data-sentry-source-file="New.tsx" />
        </div>
        <div>
          <Label data-sentry-element="Label" data-sentry-source-file="New.tsx">
            {listMode === Enums.ListMode.DEEP ? "Deep Mode" : listMode === Enums.ListMode.LIGHT ? "Light Mode" : "Ultra Light Mode"}
          </Label>
          <p className="pb-4">
            {listMode === Enums.ListMode.ULTRALIGHT ? <>
                Ultra Light mode will analyze all repositories provided, but
                none of the other contributors' repositories. Useful when you
                only want to analyze activity for a list of repositories.
              </> : listMode === Enums.ListMode.LIGHT ? <>
                Light mode conducts a quicker analysis of a large number of
                teams and contributors. It analyzes repositories committed to
                most recently, providing a balance between speed and depth.
              </> : <>
                Deep mode activates a comprehensive analysis, designed for
                smaller groups of developers. It conducts thorough backtracking
                through GitHub repositories, facilitating the identification of
                when developers joined your ecosystem.
                <br />
                <br />
                We can trace events up to three years in the past, examining
                commits in GitHub repositories to determine when users committed
                a dependency to their project that we're tracking for you, or
                participated in a project containing your dependency.
              </>}
          </p>
          <div className="flex">
            <Button className={`${listMode !== Enums.ListMode.ULTRALIGHT ? "bg-gray-300 text-black hover:text-white" : ""} rounded-r-none`} onClick={() => switchListMode(Enums.ListMode.ULTRALIGHT)} data-sentry-element="Button" data-sentry-source-file="New.tsx">
              <BoltIcon className="sm-icon mr-2" data-sentry-element="BoltIcon" data-sentry-source-file="New.tsx" />
              Ultra Light
            </Button>
            <Button className={`${listMode !== Enums.ListMode.LIGHT ? "bg-gray-300 text-black hover:text-white" : ""} rounded-none`} onClick={() => switchListMode(Enums.ListMode.LIGHT)} data-sentry-element="Button" data-sentry-source-file="New.tsx">
              <BoltIcon className="sm-icon mr-2" data-sentry-element="BoltIcon" data-sentry-source-file="New.tsx" />
              Light
            </Button>
            <Button className={`${listMode !== Enums.ListMode.DEEP ? "bg-gray-300 text-black hover:text-white" : ""} rounded-l-none`} onClick={() => switchListMode(Enums.ListMode.DEEP)} data-sentry-element="Button" data-sentry-source-file="New.tsx">
              <ClockIcon className="sm-icon mr-2" data-sentry-element="ClockIcon" data-sentry-source-file="New.tsx" />
              Deep
            </Button>
          </div>
        </div>
        {listMode == Enums.ListMode.DEEP && <>
            {" "}
            <div>
              <Label>Start Date</Label>
              <Input placeholder="YYYY-MM-DD" value={startDate} setValue={handleStartDateChange} />
              {!startDateValid && <div className="text-red-500">Start Date is invalid</div>}
            </div>
            <div>
              <Label>End Date</Label>
              <Input placeholder="YYYY-MM-DD" value={endDate} setValue={handleEndDateChange} />
              {!endDateValid && <div className="text-red-500">End Date is invalid</div>}
            </div>
          </>}
      </div>

      <div className="mt-6">
        <Button onClick={handleAddList} disabled={listName.length === 0 || !startDateValid || !endDateValid} className="ml-auto px-8" data-sentry-element="Button" data-sentry-source-file="New.tsx">
          Create
        </Button>
      </div>
    </div>;
}