import { useMemo } from "react";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { createColumnHelper, type AccessorFnColumnDef, type Row } from "@tanstack/react-table";
import { CellWithPill } from "../../../lists/MainPage/Cells";
import { Skeleton, getInfoValue } from "../../../lists/MainPage/SharedTable/utils";
import { ListsRowData } from "./index";
const columnHelper = createColumnHelper<ListsRowData>();
export const getInfoValueList = <T,>(info: {
  row: Row<ListsRowData>;
  getValue: () => T | null;
}): T | undefined => {
  return getInfoValue<T, ListsRowData>(info);
};

// Fixed widths on headers are ESSENTIAL to ensure that the table is not janky

export const useCreateListStatsColumns = () => {
  const {
    activeTeamAccount
  } = useTeamAccount();
  return (useMemo(() => {
    return [columnHelper.accessor("name", {
      header: () => <div className=" text-left w-72">List</div>,
      cell: info => <div className="flex h-max items-stretch py-2 text-left">
            <div className="w-12 px-3 text-right text-gray-400/50">
              {info.row.index + 1}
            </div>{" "}
            {info.row.original?.name ? <div>{info.row.original?.name}</div> : <div>
                <Skeleton />
              </div>}
          </div>,
      footer: info => info.column.id
    }), columnHelper.accessor("repoCount", {
      header: () => <div className=" w-28">Teams</div>,
      cell: info => {
        const repoCount = getInfoValueList<number>(info) ?? 0;
        return <div className={"text-center"}>
              {info.row.original ? <CellWithPill color={"bg-accent-subtle text-white"}>
                  {repoCount}
                </CellWithPill> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("userCount", {
      header: () => <div className=" w-28">Contributors</div>,
      cell: info => {
        const userCount = getInfoValueList<number>(info) ?? 0;
        return <div className={"text-center"}>
              {info.row.original ? <CellWithPill color={"bg-accent-subtle text-white"}>
                  {userCount}
                </CellWithPill> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("activeTeams", {
      header: () => <div className=" w-28">Active Teams</div>,
      cell: info => {
        const activeTeams = getInfoValueList<number>(info) ?? 0;
        return <div className={"text-center"}>
              {info.row.original ? <CellWithPill color={"bg-accent-subtle text-white"}>
                  {activeTeams}
                </CellWithPill> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("activeContributors", {
      header: () => <div className=" w-40">Active Contributors</div>,
      cell: info => {
        const activeContributors = getInfoValueList<number>(info) ?? 0;
        return <div className={"text-center"}>
              {info.row.original ? <CellWithPill color={"bg-accent-subtle text-white"}>
                  {activeContributors}
                </CellWithPill> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("fullTimeDevs", {
      header: () => <div className=" w-28">Full-Time</div>,
      cell: info => {
        const fullTimeDevs = getInfoValueList<number>(info) ?? 0;
        return <div className={"text-center"}>
              {info.row.original ? <CellWithPill color={"bg-accent-subtle text-white"}>
                  {fullTimeDevs}
                </CellWithPill> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("partTimeDevs", {
      header: () => <div className=" w-28">Part-Time</div>,
      cell: info => {
        const partTimeDevs = getInfoValueList<number>(info) ?? 0;
        return <div className={"text-center"}>
              {info.row.original ? <CellWithPill color={"bg-accent-subtle text-white"}>
                  {partTimeDevs}
                </CellWithPill> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    }), columnHelper.accessor("oneTimeDevs", {
      header: () => <div className=" w-28">One-Time</div>,
      cell: info => {
        const oneTimeDevs = getInfoValueList<number>(info) ?? 0;
        return <div className={"text-center"}>
              {info.row.original ? <CellWithPill color={"bg-accent-subtle text-white"}>
                  {oneTimeDevs}
                </CellWithPill> : <Skeleton />}
            </div>;
      },
      footer: info => info.column.id
    })];
  }, [activeTeamAccount.id]) as AccessorFnColumnDef<ListsRowData>[]);
};