import Link from "next/link";
import { createContext, useContext, useState, type Dispatch, type ReactNode, type SetStateAction } from "react";
import { useList } from "~/providers/ListProvider";
const dependencyNameContext = createContext<string | null>(null);
const setDependencyNameContext = createContext<Dispatch<SetStateAction<string | null>> | null>(null);
const dependencyNamesContext = createContext<string[]>([]);
export function useDependencyName() {
  return useContext(dependencyNameContext);
}
export function useSetDependencyName() {
  return (useContext(setDependencyNameContext) as Dispatch<SetStateAction<string | null>>);
}
export function useDependencyNames() {
  return useContext(dependencyNamesContext);
}
export function DependencyProvider({
  children
}: {
  children: ReactNode;
}) {
  const list = useList();
  const [dependencyName, setDependencyName] = useState<string | null>(null);
  if (!list?.trackers?.length) {
    return <div>
        <Link className="text-accent underline" href={`/lists/${list?.id ? `${list?.id}/` : ""}dependency-tracking`}>
          Start
        </Link>{" "}
        tracking a dependency to populate dashboard.
      </div>;
  }
  const dependencyNames = Array.from(new Set(list.trackers.flatMap(tracker => tracker.dependencies)));
  if (dependencyNames?.length > 0 && !dependencyName) setDependencyName(dependencyNames[0] ?? null);
  return <dependencyNameContext.Provider value={dependencyName} data-sentry-element="unknown" data-sentry-component="DependencyProvider" data-sentry-source-file="DependencyProviders.tsx">
      <setDependencyNameContext.Provider value={setDependencyName} data-sentry-element="unknown" data-sentry-source-file="DependencyProviders.tsx">
        <dependencyNamesContext.Provider value={dependencyNames} data-sentry-element="unknown" data-sentry-source-file="DependencyProviders.tsx">
          {children}
        </dependencyNamesContext.Provider>
      </setDependencyNameContext.Provider>
    </dependencyNameContext.Provider>;
}