import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import DiscordContactUserColumns, { TeamDiscordUserNullable } from "./Columns";
import Header from "../lists/MainPage/UsersTable/Header";
import Body from "../lists/MainPage/UsersTable/Body";
import { api } from "~/utils/api";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import TableLayout from "../lists/MainPage/SharedTable/TableLayout";
const UsersTable = () => {
  const {
    activeTeamAccount
  } = useTeamAccount();
  const {
    data: teamDiscordUsers,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = api.teamDiscordUser.get.useInfiniteQuery({
    teamAccountId: activeTeamAccount?.id,
    take: 10
  }, {
    getNextPageParam: lastPage => {
      const nextCursor = lastPage?.nextCursor;
      return nextCursor;
    }
  });
  const toggleHook = useState(false);
  const [, setShowNewFieldModal] = toggleHook;
  const mappedData = useMemo(() => {
    return isLoading || !teamDiscordUsers ? [null, null, null, null, null, null, null, null, null, null, null, null] : teamDiscordUsers.pages.flatMap(page => page.items);
  }, [teamDiscordUsers, isLoading]);
  const columns = useMemo(() => DiscordContactUserColumns, []);
  const table = useReactTable({
    data: mappedData,
    columns,
    getCoreRowModel: getCoreRowModel()
  });
  return <div className="flex gap-2 items-center h-[calc(100vh_-_270px)]" data-sentry-component="UsersTable" data-sentry-source-file="index.tsx">
      <TableLayout toggleHook={toggleHook} type="user" data-sentry-element="TableLayout" data-sentry-source-file="index.tsx">
        <Header<TeamDiscordUserNullable> table={table} setShowNewFieldModal={setShowNewFieldModal} hideFinalColumn={true} data-sentry-element="Header" data-sentry-source-file="index.tsx" />
        <Body<TeamDiscordUserNullable> table={table} hasNextUserPage={hasNextPage} isFetchingNextUsersPage={isFetchingNextPage} handleGetNextUserPage={fetchNextPage} hideFinalColumn={true} data-sentry-element="Body" data-sentry-source-file="index.tsx" />
      </TableLayout>
    </div>;
};
export default UsersTable;