import { UsersIcon, ChartBarSquareIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Dashboard from "~/components/lists/MainPage/Dashboard";
import { DependencyProvider } from "~/components/lists/MainPage/Dashboard/DashboardInsights/DependencyProviders";
import Settings from "~/components/lists/Settings";
import OverviewHeader from "~/components/layout/OverviewHeader";
import { useLists } from "~/providers/ListsProvider";
import ListContactInfoDashboard from "./lists/MainPage/ListContactInfoDashboard";
import Stats from "./overview/Stats";
import SortParamsProvider from "~/providers/SortParamsProvider";
import Toast from "./lists/MainPage/Toast";
import { downloadAsToml, downloadTable } from "~/lib/utils";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import UnassignedUsersTable from "./UnassignedUsersTable";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { api } from "~/utils/api";
const Overview = () => {
  const {
    lists
  } = useLists();
  const {
    activeTeamAccount
  } = useTeamAccount();
  const activeTeamAccountId = activeTeamAccount?.id;
  const handleChangeSortOrder = () => {
    return null;
  };
  const headerMenuState = useState("Teams");
  const [, setHeaderMenuState] = headerMenuState;
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingToml, setIsDownloadingToml] = useState(false);
  const repoSearchState = useState("");
  const userSearchState = useState("");
  const [repoSearch] = repoSearchState;
  const [userSearch] = userSearchState;
  const [activeHeaderMenu, setActiveHeaderMenu] = headerMenuState;
  const [showTable, setShowTable] = useState(true);
  const {
    data: session
  } = useSession();
  const router = useRouter();
  const wasClicked = router.query.overview === "true";
  const {
    query
  } = router;
  const tab = (query?.tab as string);
  useEffect(() => {
    if (wasClicked) {
      setActiveHeaderMenu("Teams");
      router.replace({
        pathname: "/"
      }).catch(err => console.error(err));
    }
    if (tab) {
      setActiveHeaderMenu(tab);
    }
  }, [tab, setActiveHeaderMenu, wasClicked]);
  const {
    data: repoContactsCount = 0
  } = api.listContact.overviewContactCount.useQuery({
    teamAccountId: activeTeamAccountId,
    type: "repo"
  });
  const {
    data: userContactsCount = 0
  } = api.listContact.overviewContactCount.useQuery({
    teamAccountId: activeTeamAccountId,
    type: "user"
  });
  const getContactStatus = () => {
    switch (headerMenuState[0]) {
      case "Teams":
        return repoContactsCount === 0;
      case "Contributors":
        return userContactsCount === 0;
      default:
        return false;
    }
  };
  const noContacts = getContactStatus();
  const [teamAccountUser] = useTeamAccountUser();
  const hiddenHeaders = teamAccountUser?.role === "admin" ? [] : [];
  const sortOrders = [{
    name: "Teams",
    icon: UsersIcon,
    UsersIcon
  }, {
    name: "Contributors",
    icon: UsersIcon,
    UsersIcon
  }, {
    name: "Stats",
    icon: ChartBarSquareIcon
  }, {
    name: "Unassigned discord users",
    icon: UserCircleIcon
  }, ...hiddenHeaders];
  if (!lists || !session?.accessToken) return null;
  const eitherContributorsOrTeams = activeHeaderMenu === "Contributors" || activeHeaderMenu === "Teams";
  const handleDownloadTable = () => {
    setIsDownloading(true);
    downloadTable(activeHeaderMenu, activeTeamAccount?.id, "overview").then(() => {
      setIsDownloading(false);
    }).catch(err => console.error(err));
  };
  const handleDownloadToml = () => {
    setIsDownloadingToml(true);
    downloadAsToml({
      activeHeaderMenu,
      teamAccountId: activeTeamAccount?.id,
      teamName: activeTeamAccount.name,
      tableName: "overview"
    }).then(() => {
      setIsDownloadingToml(false);
    }).catch(err => console.error(err));
  };
  return <SortParamsProvider userSearch={userSearch} repoSearch={repoSearch} data-sentry-element="SortParamsProvider" data-sentry-component="Overview" data-sentry-source-file="Overview.tsx">
      <OverviewHeader headerMenuType={"tabs"} newBtnText="New List" newBtnHref={`/lists/new`} headerText={"Overview"} menuItems={sortOrders} headerMenuState={headerMenuState} handleChangeSortOrder={handleChangeSortOrder} hasTableToggle={eitherContributorsOrTeams} toggleTable={(val: boolean) => {
      setShowTable(val);
    }} downloadTable={handleDownloadTable} noContacts={noContacts} isDownloading={isDownloading} downloadToml={handleDownloadToml} isDownloadingToml={isDownloadingToml} showTable={showTable} searchState={eitherContributorsOrTeams ? activeHeaderMenu === "Contributors" ? userSearchState : repoSearchState : undefined} data-sentry-element="OverviewHeader" data-sentry-source-file="Overview.tsx">
        <ListContactInfoDashboard activeHeaderMenu={activeHeaderMenu} showTable={showTable} data-sentry-element="ListContactInfoDashboard" data-sentry-source-file="Overview.tsx" />
        {activeHeaderMenu === "Dashboard" && <DependencyProvider>
            <Dashboard setSubMenu={setHeaderMenuState} />
          </DependencyProvider>}

        {activeHeaderMenu === "Stats" && <Stats />}

        {activeHeaderMenu === "Settings" && <Settings />}

        {activeHeaderMenu === "Unassigned discord users" && <UnassignedUsersTable />}

        <Toast isDashboard={true} data-sentry-element="Toast" data-sentry-source-file="Overview.tsx" />
      </OverviewHeader>
    </SortParamsProvider>;
};
export default Overview;