import { InsightsObject } from "@openqlabs/drm-db";
import { RouterOutputs } from "~/utils/api";
import { Constants } from "@openqlabs/utils";
import Header from "./Header";
import Body from "./Body";
import { useMemo } from "react";
import { getCoreRowModel, useReactTable, getSortedRowModel } from "@tanstack/react-table";
import { useCreateListStatsColumns } from "./Columns";
export interface ListStats {
  listId: string;
  insights: InsightsObject | null;
  waitingEvalsCount: number;
  doneEvalsCount: number;
  repoCount: number;
  userCount: number;
  status: string;
  updatedAt: Date;
}
export type ListsRowData = (ListStats & {
  name: string | undefined;
  considerDone: boolean;
  activeContributors: number;
  activeTeams: number;
  oneTimeDevs: number;
  partTimeDevs: number;
  fullTimeDevs: number;
}) | null;
export default function ComparisonTable({
  dashboardStatsPrep,
  lists
}: Readonly<{
  dashboardStatsPrep: Record<string, ListStats>;
  lists: RouterOutputs["list"]["listsList"];
}>) {
  const dashboardStatsArray = Object.values(dashboardStatsPrep ?? {});
  const preparedData = dashboardStatsArray.map(list => {
    const name = lists.find(c => c.id === list.listId)?.name;
    const insights = list.insights;
    const activeContributors = (insights?.customers ?? 0) + (insights?.leads ?? 0) + (insights?.newLeads ?? 0);
    const percentageDone = list.doneEvalsCount / (list.doneEvalsCount + list.waitingEvalsCount);
    const considerDone = percentageDone > Constants.PERCENTAGE_CONSIDER_DONE;
    return {
      ...list,
      considerDone,
      activeContributors,
      activeTeams: insights?.activeTeams ?? 0,
      oneTimeDevs: insights?.oneTimeDevs ?? 0,
      partTimeDevs: insights?.partTimeDevs ?? 0,
      fullTimeDevs: insights?.fullTimeDevs ?? 0,
      name
    };
  });
  const mappedData: (ListsRowData | null)[] = useMemo(() => {
    if (preparedData === null) {
      return [null, null, null, null, null, null, null];
    } else {
      return preparedData;
    }
  }, [dashboardStatsPrep]);
  const columns = useCreateListStatsColumns();
  const table = useReactTable({
    data: mappedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
  });
  return <div className="mb-20" data-sentry-component="ComparisonTable" data-sentry-source-file="index.tsx">
      <div className="flex justify-between pt-2 mb-4 ">
        <div className="flex items-center gap-2">
          <h3 className="text-xl font-semibold">Lists Comparison</h3>
        </div>
      </div>
      <div className="w-full mb-4">Lists stats overview & comparison</div>
      <div>
        <div className="-mr-8 overflow-hidden text-muted">
          <div id="table-container" className="h-max-[calc(100vh_-_220px)] w-full overflow-scroll -mr-8">
            <table className="overflow-x-hidden">
              <Header table={table} data-sentry-element="Header" data-sentry-source-file="index.tsx" />
              <Body table={table} data-sentry-element="Body" data-sentry-source-file="index.tsx" />
            </table>
          </div>
        </div>
      </div>
    </div>;
}