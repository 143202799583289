import VennDiagram from "./VennDiagram";
import ComparisonTable from "./ComparisonTable/index";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { Constants } from "@openqlabs/utils";
import { InsightsPerTracker, InsightsObject } from "@openqlabs/drm-db";
import { api, type RouterOutputs } from "~/utils/api";
import { useLists } from "~/providers/ListsProvider";
import SortParamsProvider from "~/providers/SortParamsProvider";

// for examples of venn.js: https://github.com/benfred/venn.js

type ListStats = RouterOutputs["listEvaluation"]["getAllListsDashboardStats"];
export default function Stats() {
  const {
    activeTeamAccount
  } = useTeamAccount();
  const {
    data: overviewEvaluation
  } = api.overviewEvaluation.get.useQuery({
    teamAccountId: activeTeamAccount.id
  });
  const dataSet = overviewEvaluation?.vennDataSet ?? [];
  const tooMuchData = dataSet.length > Constants.MAX_ARRAY_SIZE_VENN;
  const {
    lists
  } = useLists();
  const listIds = lists?.map(list => list.id) ?? [];
  const dashboardStats = (api.listEvaluation.getAllListsDashboardStats.useQuery({
    teamAccountId: activeTeamAccount.id
  }).data as ListStats);
  const dashboardStatsPrep = dashboardStats?.filter(x => listIds.includes(x.listId))?.reduce((acc, statsList) => {
    const tracker = lists?.find(list => list.id === statsList.listId)?.trackers?.[0];
    const trackerId = tracker?.id;
    const dependency = tracker?.dependencies?.[0];
    const insightsPerTracker = (statsList?.insightsPerTracker as InsightsPerTracker | null);
    const insights = ((trackerId && dependency && insightsPerTracker?.[trackerId]?.[dependency]) as InsightsObject | null);
    acc[statsList.listId] = {
      listId: statsList.listId,
      insights: insights ?? null,
      waitingEvalsCount: statsList.waitingEvalsCount ?? 0,
      doneEvalsCount: statsList.doneEvalsCount ?? 0,
      repoCount: statsList.repoCount ?? 0,
      userCount: statsList.userCount ?? 0,
      status: statsList.status,
      updatedAt: statsList.updatedAt
    };
    return acc;
  }, ({} as Record<string, {
    listId: string;
    insights: InsightsObject | null;
    waitingEvalsCount: number;
    doneEvalsCount: number;
    repoCount: number;
    userCount: number;
    status: string;
    updatedAt: Date;
  }>));
  const totalEvalsCount = Object.values(dashboardStatsPrep ?? {})?.reduce((acc, list) => {
    acc.waitingEvalsCount += list.waitingEvalsCount;
    acc.doneEvalsCount += list.doneEvalsCount;
    return acc;
  }, {
    waitingEvalsCount: 0,
    doneEvalsCount: 0
  });
  const percentageDone = totalEvalsCount.doneEvalsCount / (totalEvalsCount.waitingEvalsCount + totalEvalsCount.doneEvalsCount);
  const considerDone = percentageDone > Constants.PERCENTAGE_CONSIDER_DONE;
  return <div className="h-[calc(100vh_-_270px)] overflow-scroll" data-sentry-component="Stats" data-sentry-source-file="index.tsx">
      <SortParamsProvider userSearch="" repoSearch="" data-sentry-element="SortParamsProvider" data-sentry-source-file="index.tsx">
        <ComparisonTable dashboardStatsPrep={dashboardStatsPrep} lists={lists} data-sentry-element="ComparisonTable" data-sentry-source-file="index.tsx" />
        {!tooMuchData && <VennDiagram overviewEvaluation={overviewEvaluation} considerDone={considerDone} />}
      </SortParamsProvider>
    </div>;
}