import { UserIcon } from "@heroicons/react/24/outline";
import { createColumnHelper } from "@tanstack/react-table";
import type { TeamDiscordUserContact } from "@openqlabs/drm-db";
import { Skeleton, TruncatedString } from "../lists/MainPage/SharedTable/utils";
import Button from "../base/Button";
import { Dialog } from "@headlessui/react";
import { useState } from "react";
import Input from "../base/Input";
import { api } from "~/utils/api";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import Link from "next/link";
export type TeamDiscordUserNullable = TeamDiscordUserContact | null;
const columnHelper = createColumnHelper<TeamDiscordUserNullable>();
const MatchGithubUser = ({
  id
}: {
  id: string;
}) => {
  const {
    activeTeamAccount
  } = useTeamAccount();
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const {
    mutate
  } = api.teamDiscordUser.match.useMutation({
    onSuccess: () => {
      setIsOpen(false);
    }
  });
  const handleAdded = () => {
    mutate({
      teamAccountId: activeTeamAccount?.id,
      githubName: value,
      id
    });
  };
  return <>
      <Button onClick={() => setIsOpen(true)} className="rounded-none w-full" data-sentry-element="Button" data-sentry-source-file="Columns.tsx">
        Link Contributor
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="relative z-50" data-sentry-element="Dialog" data-sentry-source-file="Columns.tsx">
        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white px-16 py-4 text-left align-middle shadow-xl transition-all" data-sentry-element="unknown" data-sentry-source-file="Columns.tsx">
            <Dialog.Title className=" flex flex-col content-center items-center justify-center gap-2 text-lg leading-6 " data-sentry-element="unknown" data-sentry-source-file="Columns.tsx">
              <span className="py-2 text-2xl">
                Link Discord Account to Github User
              </span>
            </Dialog.Title>
            <Dialog.Description data-sentry-element="unknown" data-sentry-source-file="Columns.tsx">
              This will add the discord user's info to the main table, based on
              the github username you add. If you add a github username that
              isn't in the main table, we'll automatically add it for you. If it
              doesn't exist on github, nothing will happen.
            </Dialog.Description>

            <div className="flex gap-4 py-4">
              <Input value={value} setValue={setValue} data-sentry-element="Input" data-sentry-source-file="Columns.tsx" />
              <Button onClick={handleAdded} className="px-10 flex-1" data-sentry-element="Button" data-sentry-source-file="Columns.tsx">
                Link
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>;
};
const DiscordContactUserColumns = [columnHelper.accessor("discordUserName", {
  header: () => <span>Name</span>,
  cell: info => <div className="flex h-max items-stretch py-2  text-left ">
        <div className="w-12 px-3 text-right text-gray-400/50">
          {info.row.index + 1}
        </div>{" "}
        {info.row.original ? <div className="flex h-full w-72 gap-4 px-2">
            <UserIcon className="h-6 w-6" />
            <Link href={`/discord-user/${info.row.original.id}/insights`}>
              {info.getValue()}
            </Link>
          </div> : <div className="w-72">
            <Skeleton />
          </div>}
      </div>,
  footer: info => info.column.id
}), columnHelper.accessor("discordLatestActivityText", {
  header: () => <div className="w-80">Latest Activity</div>,
  cell: info => {
    const discordLatestActivityTime = info?.row?.original?.discordLatestActivityTime;
    const readableTime = discordLatestActivityTime && new Date(discordLatestActivityTime).toLocaleString();
    return <div className={` flex text-sm `}>
          {info.row.original ? <TruncatedString string={`Sent message: ${info.getValue()} at ${readableTime}`} maxLength={35} /> : <div className="px-2">
              <Skeleton />
            </div>}
        </div>;
  },
  footer: info => info.column.id
}), columnHelper.accessor("discordMessageCount", {
  header: () => <div className="w-80">Message count</div>,
  cell: info => {
    return <div className={`flex text-sm `}>
          {info.row.original ? <TruncatedString string={info.getValue()?.toString() ?? ""} maxLength={35} /> : <div className="px-2">
              <Skeleton />
            </div>}
        </div>;
  },
  footer: info => info.column.id
}), columnHelper.accessor("id", {
  header: () => <div className="w-40">Match Account</div>,
  cell: info => {
    return <div className={`flex text-sm `}>
          {info.row.original ? <MatchGithubUser id={info.getValue()} /> : <div className="px-2">
              <Skeleton />
            </div>}
        </div>;
  },
  footer: info => info.column.id
})];
export default DiscordContactUserColumns;